.containerCardCertificate{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
}

@media only screen and (max-width : 500px) {

.containerCardCertificate{
    margin-top: 15px;
}
}