.containerCardPortafolio{
    width: 48%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 10px !important;
    margin-bottom: 20px !important;
}

.containerCards{
    flex-direction: row;
    display: flex;
    justify-items: center;
    align-items: center;
}

.containerCardPortafolio h3{
    color: var(--color-pink-primary);
    text-align: center;
    font-weight: bolder;
}

.containerCardPortafolio .containerTags{
    display: flex;
    flex-direction: row;
}

.containerCardPortafolio .containerTags .tag{
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    border-radius: 35%;
    background-color: #fff5db;
    color: var(--color-pink-primary);
    font-weight: bold;
}

.containerCardPortafolio .containerDescription{
    margin-top: 12px;
    margin-bottom: 12px;
}

.containerButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.containerCardPortafolio .containerButton .buttonVisitPage{
    justify-self: end;
    padding-top: 12px;
    
    border-width: 0px;
    background-color: transparent;
    color: var(--color-pink-primary);
    font-size: 16px;
    cursor: pointer;
}

.containerCardPortafolio .containerButton .buttonVisitPage:hover{
    text-decoration: underline;
}

.containerCardPortafolio .containerButton img{
    width: 24px;
    margin-top: 10px;
    
}

/* Cuando la pantalla es menor a 900px  (tablets y teléfonos inteligentes)*/
@media only screen and (max-width : 900px) {
    .containerCards{
        flex-direction: column !important;
    }

    .containerCardPortafolio {
        width: 95%;
    }

}

@media only screen and (max-width : 500px) {
  
}