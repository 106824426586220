.CardKnowledge{
    border-radius: 7px;
    padding: 8px;
    width: 31%;
    text-align: center;
    margin: 12px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    display: flex;
}


.color-1{
    background-color: #fff5db;
}

.color-2{
    background-color: #f6ddff;
}

/* Cuando la pantalla es menor a 900px  (tablets y teléfonos inteligentes)*/
@media only screen and (max-width : 900px) {

    .CardKnowledge{
        width: 47%;
    }
}

@media only screen and (max-width : 500px) {
    .CardKnowledge{
        width: 100%;
        min-height: 50px;
    }

}