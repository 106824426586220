
.divFooter{
    justify-content: center;
    margin: auto;
    text-align: center;
    padding: 13px;
    border-top-style: solid;
    border-top-width: 2px;
    margin-top: 10px;
    border-top-color: #58a189;
    background-color: #58a18936;
}

.divFooter img{ 
    width: 28px;
    height: 28px;
    margin-left: 9px;
    cursor: pointer;
}

.divFooter p{
    font-style: italic;
    color: var(--color-pink-primary);
    margin-bottom: 25px !important;
}

.containerFooter{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.containerFooter h5{
    margin-top: 5px !important;
}